import Cookie from 'js-cookie';

const KEY_PREFIX = 'pos.';
const COOKIE_EXPIRE_DAYS = 7;

// keys related to user
export const USER_KEYS = {
  token: `${KEY_PREFIX}token`,
  userInfo: `${KEY_PREFIX}userInfo`,
  ownerId: `${KEY_PREFIX}ownerId`,
  refreshToken: `${KEY_PREFIX}refreshToken`,
  expireTime: `${KEY_PREFIX}expireTime`,
  identity: `${KEY_PREFIX}identity`,
  restaurantId: `${KEY_PREFIX}restaurantId`,
  authorization: `${KEY_PREFIX}authorization`,
  signedPOSContract: `${KEY_PREFIX}signedPOSContract`,
  majorIndexLayout: `${KEY_PREFIX}majorIndexLayout`,
  conversionIndexLayout: `${KEY_PREFIX}conversionIndexLayout`,
  visibleMajorIndexes: `${KEY_PREFIX}visibleMajorIndexes`,
  visibleConversionIndexes: `${KEY_PREFIX}visibleConversionIndexes`,
  activated: `${KEY_PREFIX}activated`,
  zoneInfo: `${KEY_PREFIX}zoneInfo`,
  currentRgId: `${KEY_PREFIX}currentRgId`,
  intercomRestaurantId: `${KEY_PREFIX}intercomRestaurantId`,
  limitPutId: `${KEY_PREFIX}limitPutId`,
  notPrintOnTicketId: `${KEY_PREFIX}notPrintOnTicketId`,
};

export const KEYS = {
  ...USER_KEYS,
  email: `${KEY_PREFIX}email`,
  locale: `${KEY_PREFIX}locale`,
  version: `${KEY_PREFIX}version`,
  salesReportLayout: `${KEY_PREFIX}salesReportLayout`,
  payoutReportLayout: `${KEY_PREFIX}payoutReportLayout`,
  navMenuScrollTop: `${KEY_PREFIX}navMenuScrollTop`,
  isInApp: `${KEY_PREFIX}isInApp`,
  isInCPS: `${KEY_PREFIX}isInCPS`,
};

export const setLocalItem = (items) => {
  for (let key in items) {
    const value = items[key];
    if (typeof value === 'string') {
      localStorage.setItem(key, value);
    } else {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }
};

export const getLocalItem = (key, defaultValue = null) => {
  if (typeof window === 'undefined') return null;
  return localStorage.getItem(key) || defaultValue;
};

export const removeLocalItem = (keys) => {
  if (!keys) return;

  if ({}.toString.call(keys) === '[object String]') {
    keys = [keys];
  }

  keys.forEach((key) => {
    localStorage.removeItem(key);
  });
};

export const setSessionItem = (items) => {
  for (let key in items) {
    const value = items[key];
    if (typeof value === 'string') {
      sessionStorage.setItem(key, value);
    } else {
      sessionStorage.setItem(key, JSON.stringify(value));
    }
  }
};

export const getSessionItem = (key, defaultValue = null) => {
  if (typeof window === 'undefined') return null;
  return sessionStorage.getItem(key) || defaultValue;
};

export const removeSessionItem = (keys) => {
  if (!keys) return;

  if ({}.toString.call(keys) === '[object String]') {
    keys = [keys];
  }

  keys.forEach((key) => {
    sessionStorage.removeItem(key);
  });
};

export const clearSession = () => {
  removeSessionItem(Object.values(USER_KEYS));
};

export const setCookie = (items) => {
  for (const key in items) {
    const val = typeof items[key] === 'object' ? JSON.stringify(items[key]) : items[key];
    Cookie.set(key, val, { secure: true, path: '/', expires: COOKIE_EXPIRE_DAYS });
  }
};

export const getCookie = (name) => {
  return Cookie.get(name);
};

export const deleteCookie = (key) => {
  Cookie.remove(key, { path: '/' });
};

export const deleteAllCookies = () => {
  const keys = [USER_KEYS.token, USER_KEYS.refreshToken, USER_KEYS.currentRgId];
  keys.forEach((key) => {
    deleteCookie(key);
  });
};
